import * as React from "react";
import { Helmet } from "react-helmet";
import LayoutPrimary from "../templates/LayoutPrimary";
import { galleryPageContainer } from "../styles/components/galleries.module.scss";
import { h1, sub } from "../styles/components/titles.module.scss";
import "../styles/components/content.module.scss";
import { useMediaQuery } from "react-responsive";
import GalleryFull from "../components/galleries/GalleryFull";
import { list3 } from "../styles/components/lists.module.scss";
import { serviceInfoBtns } from "../styles/components/blocks.module.scss";
const GalleryPage = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

  const listItemStyle = {
    marginLeft: isMobile ? "10px" : "50px",
    width: "100%",
  };
  return (
    <LayoutPrimary>
      <Helmet>
        <title>
          Party Boat Charter Photo Gallery | Tampa Bay Boating Adventures
        </title>
        <meta
          name="description"
          content="See photos of our private party boat charter trips along the Tampa, Florida coastline and book yours online today!"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <main className="container">
        <div className="row center this-pad-y-4-lg this-pad-y-2">
          <div className="col-xxs-12">
            <div>
              <h1>
                <span className={sub}>FAQs</span> Trip Information
              </h1>
              <div
                className={serviceInfoBtns}
                style={{
                  display: "flex",

                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <h2>What can you tell me about your trips?</h2>
                <p>
                  Our trips are twelve-passenger party boat cruises designed for
                  those that want to step things up a notch when compared to a
                  traditional pontoon boat charter company. Our vessel is a
                  spacious deck boat that has a top-of-the-line sound system,
                  comfy faux-leather seats, color changing lights, a young and
                  fun crew, and much more. Our trips, depending on the duration,
                  will take you through dazzling Downtown Tampa, island hopping
                  through the bay, and near luxurious scenery surrounding the
                  Tampa Bay Area. Our most popular trips are 4-hour or 6-hour
                  party boat charters. If you’re looking for a shorter, relaxing
                  evening charter, our Sunset Charters are also an option. We
                  offer the ability to step things up with a bottle girl/boy,
                  DJ, or professional photographer that will accompany you
                  throughout the day.
                </p>
                <h2>How many people can you take on a trip?</h2>
                <p>
                  Our trips are limited to twelve passengers due to Coast Guard
                  regulations. However, we work with other local charter
                  companies that can also offer twelve passengers. We can
                  combine our trips to take more than twelve guests, but they
                  will be on separate boats.
                </p>
                <h2>
                  Do you have a recommendation for someone who can take my group
                  of 12+ passengers?
                </h2>
                <p>
                  Yes, we have a vessel that can take up to 30 passengers. This
                  vessel is bare-bones and is not a party vessel, but it can
                  comfortably fit 30 passengers and take you to many of the same
                  destinations that our primary vessel, Adventure I, can. This
                  vessel is only available by special request.
                </p>
                <h2>Do you provide alcohol on your trips?</h2>
                <p>
                  No, we cannot legally provide alcohol on our trips. We
                  encourage you to bring your own. But, we can provide mixers
                  and other non alcoholic drinks, as well as water. We can also
                  stop at bars on the water while we’re cruising.
                </p>
                <h2>Do you have coolers on board?</h2>
                <p>
                  Yes, under each seat is cooler space. You are more than
                  welcome to bring your own cooler, however we have ample ice
                  and cooler space.
                </p>
                <h2>Can I bring my dog?</h2>
                <p>
                  Yes, if your dog is well behaved and under 20 lbs. Larger dogs
                  can rip the sea-dek and seats, which cost hundreds of dollars
                  to replace. If your dog causes any damage, you will be
                  responsible for the fees to fix the damage.
                </p>
                <h2>Can we shorten a charter?</h2>
                <p>
                  Yes, this most often happens due to sea sickness, heat,
                  weather, or simply getting too drunk. There are no refunds if
                  you request to shorten the length of the charter. This would
                  normally be asked during the charter to the captain.
                </p>
                <h2>Can we go to the beaches in St. Pete?</h2>
                <p>
                  No, the beaches in St. Pete are too far. For any trip longer
                  than six hours, we can travel to Downtown St. Pete.
                </p>
                <h2>Will we see coral on our trip?</h2>
                <p>No, there are no coral reefs in Tampa Bay.</p>
                <h2>Can we extend a charter?</h2>
                <p>
                  Yes, you can more than likely extend the length of the
                  character, assuming there are no other trips booked that would
                  conflict with the schedule. This will be billed at $100 per
                  additional hour. If there is not another charter booked that
                  would have a scheduling conflict, then the charter can be
                  extended. This would normally be asked during the charter to
                  the captain.
                </p>
                <h2>Can you go tubing?</h2>
                <p>
                  Yes, we do provide the option to go tubing. There are extra
                  precautions and safety that must occur if someone wants to go
                  tubing. This activity is at the discretion of the captain on
                  board at the time.
                </p>
                <h2>Can we go snorkeling?</h2>
                <p>
                  No, we cannot go snorkeling. The water in Tampa Bay is not
                  clear enough to see any marine life.
                </p>
                <h2>Will we see dolphins or manatees?</h2>
                <p>
                  Potentially! About 75% of the time, we do see dolphins on a
                  four-hour or longer charter. Seeing manatees is a bit more
                  rare, about 20% of the four-hour or longer charters do see
                  manatees, but it is rare.
                </p>
                <h2>Are there sharks in the water?</h2>
                <p>
                  Yes, it is a natural body of brackish water. Hundreds of
                  people per day swim in Tampa Bay and do not have any issues
                  with sharks. The most common sharks are bonnethead sharks,
                  which feed on shrimp, snails, and seagrass. They are not
                  dangerous to humans.
                </p>
                <h2>What snacks do you provide?</h2>
                <p>
                  The most common snacks are chips, however we encourage you to
                  bring your own food on charters. Our snacks are just that -
                  they're not meals.
                </p>
                <h2>What other items, like sunscreen, should I bring?</h2>
                <p>
                  We recommend bringing any alcohol you would like to consume,
                  meals, energy drinks, towels, sunglasses, and any “party
                  favors” that you would like to consume. We always have extra
                  sunscreen and water on board.
                </p>
                <h2>Who drives the boat?</h2>
                <p>
                  We have a qualified, professionally licensed captain that will
                  drive the boat for you. They are fully licensed and insured.
                  We do not allow guests to drive the vessel. If you will be
                  taking more than six guests, you can choose a captain to drive
                  the vessel from our list of qualified captains.
                </p>
                <h2>What “party favors” can I partake in on the boat?</h2>
                <p>
                  It’s a party boat! If you would like to bring any party
                  favors, we encourage you to bring them. However, you are
                  liable for your action and any potential damages while on
                  board the vessel and must act respectfully. We reserve the
                  right to deny any guests the consumption of “party favors.”
                </p>
                <h2>Do you do boat rentals?</h2>
                <p>
                  No, we do not rent out our party boat in the conventional
                  sense. We do offer a bareboat agreement, which allows you to
                  rent our boat and choose from our list of qualified captains.
                  The boat is strictly used for trips that are captained by a
                  licensed and insured professional captains.
                </p>
                <h2>How far can you take the boat? </h2>
                <p>
                  We prefer to stay within a mile or two of the shore, unless we
                  are traveling to Downtown St. Pete. If we drive further than
                  that, the wind and waves can make the trip uncomfortable. It’s
                  in everyone's best interest if we browse the sites local to
                  Downtown Tampa. It will always be the captain’s discretion as
                  to where we can take the vessel.
                </p>
                <h2>Where can you pick up from?</h2>
                <p>
                  We can pick up from any public dock in Downtown Tampa. Our
                  preferred pickup spots are the Davis Island Boat Ramp
                  (801-1399 Severn Ave, Tampa, FL 33606) or Heights District
                  Public Boat Dock at Armature Works. If you have another place
                  in mind, let us know! We can most likely accommodate this
                  request, as long as the dock is public.
                </p>
                <h2>Where can you drop me off after the trip?</h2>
                <p>
                  Same as above, we are able to drop you off at any public dock
                  in Downtown Florida, however we recommend being dropped off at
                  the same place you started.
                </p>
                <h2>How early should I be there?</h2>
                <p>
                  Please arrive 15 minutes prior to the trip departing. This
                  will ensure you have enough time to load up, sign waivers (if
                  you haven’t already), learn about the safety features of the
                  vessel, and understand the game plan for the day’s party!
                </p>
                <h2>
                  If I want to take some people during part of the trip and
                  different people during a different portion of the trip, is
                  that an option?
                </h2>
                <p>
                  Yes, we are able to take a certain group of individuals in the
                  morning and a different group of individuals in the afternoon.
                  We are able to swap passengers at any public boat dock in
                  Tampa, assuming a spot is available.
                </p>
                <h1>Bookings & Payment</h1>
                <h2>
                  What’s the latest time I can change the date of my charter?
                </h2>
                <p>
                  You can change the time or date of your charter up to 24 hours
                  before the charter is set to depart.
                </p>
                <h2>How do I book a trip?</h2>
                <p>
                  Trips can be booked through our website,
                  <a href="http://tampabayboatingadventures.com">
                    tampabayboatingadventures.com
                  </a>
                  , or over the phone at (813) 742-8178 with our team. We
                  utilize a booking software called Fareharbor that will walk
                  you through the booking process.
                </p>
                <h2>How soon before the trip can I book? </h2>
                <p>
                  Trips can be booked 24 hours out from the time that the trip
                  will depart. If TBBA has captains available, they will make
                  every effort to ensure we can meet the clients request and run
                  the trip.
                </p>
                <h2>What is your cancellation policy?</h2>
                <p>
                  Customers will receive a full refund or credit with 24 hours
                  notice of cancellation. Customers will also receive a full
                  refund or credit in case of operator cancellation due to
                  weather or other unforeseen circumstances. Contact us by phone
                  to cancel or inquire about a cancellation. No-shows will be
                  charged the full price.
                </p>
                <h2>What is your inclement weather policy?</h2>
                <p>
                  If a trip must be canceled due to weather, we will offer a
                  full refund. If a trip must be cut short due to inclement
                  weather, such as storms or high winds, and has not had over
                  50% of the duration of the trip completed, we will offer a
                  follow-up trip for the remaining duration of the charter.
                </p>
                <h2>
                  Do you offer any group trips that I could sign up for, as an
                  individual?
                </h2>
                <p>
                  No, we do not currently offer any mix-and-match group trips,
                  however it is something we have considered. Our trips are
                  limited to twelve-guests and will only ever have the people
                  that you signed up with. There will never be any random
                  passengers.
                </p>
                <h2>What forms of payment are accepted?</h2>
                <p>
                  We accept all major credit cards. If you would like to book a
                  trip with cash, PayPal, Zelle, CashApp, Venmo, etc, please let
                  us know before booking. This is most often doable.
                </p>
                <h2>
                  What days of the week and times are available for charters?
                </h2>
              </div>
              <div
                style={{
                  display: "flex",
                  textAlign: "center",
                  flexDirection: "column",
                  justifyContent: "flex-start",

                  marginTop: "-50px",
                  alignContent: "center",
                  marginLeft: "50px",
                  alignItems: "flex-start",
                }}
              >
                <p>4, 6, or 8-Hour Party Boat Charter</p>
                <p
                  style={{
                    marginTop: "-20px",
                    marginLeft: "-40px",
                    width: "100%",
                  }}
                >
                  These trips are always available on Saturday and Sunday,
                  however they can be booked on weekdays during our busier
                  months.
                </p>
                <p
                  style={{
                    marginTop: "-20px",
                    marginLeft: "-0px",
                    width: "100%",
                  }}
                >
                  We can be flexible on the start time of the charter, but the
                  standard allowable start times are as follows:
                </p>
              </div>
              <ul
                style={{ display: "block", marginLeft: "50px" }}
                className={list3}
              >
                <li>4-Hour Party Boat Charter:</li>
                <li style={listItemStyle}>
                  Weekends: 10 AM - 6 PM. The last charter will end at 10 PM.
                </li>
                <li>6-Hour Party Boat Charter:</li>
                <li style={listItemStyle}>
                  Weekends: 10 AM - 4 PM. The last charter will end at 10 PM.
                </li>
                <li>8-Hour Party Boat Charter:</li>
                <li style={listItemStyle}>
                  Weekends: 10 AM - 2 PM. The last charter will end at 10 PM.
                </li>
              </ul>
              <div>
                <h2>Sunset Charters</h2>
                <p>
                  These charters are available any day of the week. The actual
                  start time and end time of the trip will be determined based
                  on the time of the sunset.
                </p>
                <ul
                  style={{ display: "block", marginLeft: "50px" }}
                  className={list3}
                >
                  <li>Weekdays and Weekends: Variable, Often 5 PM</li>
                </ul>
                <h2>Wave Rave | 3-Hour NIGHT Party Boat Charter</h2>
                <p>
                  These charters are available on Thursday, Friday, or Saturday.
                  We can be flexible on the start time of the charter, but the
                  standard allowable start times are as follows:
                </p>
                <ul
                  style={{ display: "block", marginLeft: "50px" }}
                  className={list3}
                >
                  <li style={{ width: "100%" }}>
                    Thursday, Friday, Saturday: 7 PM - 11 PM. The last charter
                    will end at 2 AM.
                  </li>
                </ul>
                <h2>Other Trips</h2>
                <p>
                  Other trips include promotional trips with models, overnight
                  island parties, and other trips that are not listed on our
                  website. These trips are doable but they require additional
                  planning for the captain and crew, so please allow ample
                  planning times for us to make it happen.
                </p>
                <h1>Trip Pricing</h1>
                <h2>How much do trips start at?</h2>
                <p>
                  The price listed below is the standard pricing breakdown.
                  Additional options are shown underneath each price. Add-on
                  options include Professional DJ, Professional Photographer, or
                  Model/Bottle-Girl. They add another level of satisfaction to
                  the trip.
                </p>
                <p>The current price breakdown is as follows:</p>
                <ul
                  style={{ display: "block", marginLeft: "50px" }}
                  className={list3}
                >
                  <li>Sunset Charter: </li>
                  <li style={listItemStyle}>Base: $549</li>
                  <li style={listItemStyle}>Base + Taxes & Fees: $614.88</li>
                  <li style={listItemStyle}>
                    Base + Taxes & Fees + Minimum Recommended Tip: $707.11
                  </li>
                  <li style={listItemStyle}>Add-ons: $299</li>
                </ul>
                <ul
                  style={{ display: "block", marginLeft: "50px" }}
                  className={list3}
                >
                  <li>4-Hour Charter:</li>
                  <li style={listItemStyle}>Base: $824</li>
                  <li style={listItemStyle}>Base + Taxes & Fees: $922.88</li>
                  <li style={listItemStyle}>
                    Base + Taxes & Fees + Minimum Recommended Tip: $1,061.32
                  </li>
                  <li style={listItemStyle}>Add-ons: $349</li>
                </ul>
                <ul
                  style={{ display: "block", marginLeft: "50px" }}
                  className={list3}
                >
                  <li>6-Hour Charter:</li>
                  <li style={listItemStyle}>Base: $924</li>
                  <li style={listItemStyle}>Base + Taxes & Fees: $1,034.88</li>
                  <li style={listItemStyle}>
                    Base + Taxes & Fees + Minimum Recommended Tip: $1,190.12
                  </li>
                  <li style={listItemStyle}>Add-ons: $479</li>
                </ul>
                <ul
                  style={{ display: "block", marginLeft: "50px" }}
                  className={list3}
                >
                  <li>8-Hour Charter: </li>
                  <li style={listItemStyle}>Base: $1024</li>
                  <li style={listItemStyle}>Base + Taxes & Fees: $1,146.88</li>
                  <li style={listItemStyle}>Base + Taxes & Fees: $1,146.88</li>
                  <li style={listItemStyle}>Add-ons: $599</li>
                </ul>
                <ul
                  style={{ display: "block", marginLeft: "50px" }}
                  className={list3}
                >
                  <li>3-Hour NIGHT Wave Rave:</li>
                  <li style={listItemStyle}>Base: $924.00</li>
                  <li style={listItemStyle}>Base + Taxes & Fees: $1,034.88</li>
                  <li style={listItemStyle}>
                    Base + Taxes & Fees + Minimum Recommended Tip: $1,190.12
                  </li>
                  <li style={listItemStyle}>Add-ons: $299</li>
                </ul>

                <ul
                  style={{ display: "block", marginLeft: "50px" }}
                  className={list3}
                >
                  <li>Overnight Island Party:</li>
                  <li style={listItemStyle}>Base: $1,749.00</li>
                  <li style={listItemStyle}>Base + Taxes & Fees: $1,958.88</li>
                  <li style={listItemStyle}>
                    Base + Taxes & Fees + Minimum Recommended Tip: $2,252.71
                  </li>
                  <li style={listItemStyle}>Add-ons: $599</li>
                </ul>

                <ul
                  style={{ display: "block", marginLeft: "50px" }}
                  className={list3}
                >
                  <li>Other:</li>
                  <li style={listItemStyle}>Base: Variable.</li>
                </ul>
                <h2>How much should I tip?</h2>
                <p>
                  Our charter boat captains, mates, and contractors rely on tips
                  as part of their income. Tipping in the charter boat industry
                  is standard, just the same as it is within any service
                  industry. Similar to a water or waitress, please consider
                  tipping 15% - 25%, depending on your satisfaction with their
                  service.
                </p>
                <h2>How do I tip?</h2>
                <p>
                  Tipping can be done ahead of time through our FareHarbor
                  booking system or can be done in cash or Venmo once the trip
                  is complete.
                </p>
                <h2>How do I leave a review?</h2>
                <p>
                  You can leave a review by Googling “Tampa Bay Boating
                  Adventures” and leaving a review on our <br /> Google Business
                  Profile. Reviews can also be left on Yelp.
                  <br />
                  Google Business Profile Review Link:
                  <a href="https://g.page/r/CRgsFa0vDlGjEAE">
                    <strong>
                      <u>Leave a review on Google</u>
                    </strong>
                  </a>
                  <br />
                  Yelp Review Link:
                  <a href="https://www.yelp.com/biz/tampa-bay-boating-adventures-tampa-2#reviews">
                    <strong>
                      <u>Leave a review on Yelp</u>
                    </strong>
                  </a>
                </p>

                <h2>Boat & Crew Information</h2>
                <h2>Why are you limited to twelve people?</h2>
                <p>
                  While our boat can comfortably hold 14 passengers, our
                  licensing through the Coast Guard currently limits us to 12
                  passengers through a bareboat agreement.
                </p>
                <h2>What destinations are available?</h2>
                <p>
                  Most often, we travel through Downtown Tampa, stopping at
                  local favorites for drinks, such as Ricks on the Water,
                  Armature Works, and the Colombia Cafe. We will also explore
                  Harbor Island and Davis Island, marveling at the architecture
                  and natural beauty. Dropping an anchor and floating off of
                  Davis Island is often the best place to watch the sunset. For
                  longer trips (4+ Hours), we will travel to Beer Can Island, a
                  party island in the bay that has a 40ft blow up slide,
                  multiple bars, food trucks, and other attractions, including a
                  beach and a sandbar party. Additionally, we will stop at
                  Fantasy Island, which is a small, peaceful island located near
                  Beer Can Island that offers white sand beaches and a perfect
                  opportunity for a photoshoot.
                </p>
                <h2>
                  Can we travel to St. Pete (St. Petersburg, FL) on our trip?
                </h2>
                <p>
                  For any trip under 6 hours, we cannot travel to St. Pete. It
                  is simply too far. For any trip over 6 hours, we can make the
                  trip if the winds are not too strong. This will vary depending
                  on the day that is requested. Please escalate this to your
                  superior if the client is insistent on traveling to St. Pete.{" "}
                </p>
                <h2>Can we barhop through downtown Tampa?</h2>
                <p>
                  Yes, this is a great way to spend your time on a shorter trip
                  or an even better way to pre-game on a longer trip - or even
                  wind down after a long day in the sun. Our favorite bar
                  hopping locations include the Colombia Cafe located near the
                  Tampa History Museum, Armature Works, and Ricks on the River,
                  located further up the Hillsborough River. If you have another
                  place in mind that has a public dock, then we can stop there
                  as well.
                </p>
                <h2>Can we pick our captain for the trip?</h2>
                <p>
                  No, captains will be assigned based on availability. If you
                  have a specific captain in mind, please inform us. We will do
                  our best to accommodate your request. If this is a bareboat
                  charter and the group has more than six guests, you will have
                  the ability to choose a captain from our vetted list of
                  qualified captains.
                </p>
                <h2>Do you have a mate that you use on board?</h2>
                <p>
                  Yes, most trips will include a mate, however they are not
                  required and are based on availability.
                </p>
                <h2>Add-On Services</h2>
                <h2>What is a “Professional DJ add-on?”</h2>
                <p>
                  Our professional DJs are talented individuals that play at
                  some of the best nightclubs in Tampa. These DJs will come on
                  the boat, bring their DJ deck, plug in live directly to the
                  sound system, and mix playlists curated specifically for your
                  tastes all day long. This is our favorite add-on! Please be
                  sure to tip your DJ!
                </p>
                <h2>What is a “Professional Photographer add-on?”</h2>
                <p>
                  Our professional photographers are independent photographers
                  that own their own photography businesses and have experience
                  shooting in bright light and wet conditions. They’ll bring
                  their gear and allow you to relax while we capture your trip
                  in the highest-quality, Instagram-ready photos. This add-on is
                  a necessity for proposals, bachelor/bachelorette parties, or
                  even just capturing how awesome your experience was. Please be
                  sure to tip your photographer!
                </p>
                <h2>What is a “Professional Bottlegirl/Model add-on?”</h2>
                <p>
                  Our professional bottlegirls and models are
                  industry-professionals that dance and entertain in some of the
                  top nightclubs in Tampa. These girls will come on board and
                  serve you drinks, entertain, and feature in any photos that
                  you’d like to take. Please be sure to tip your bottlegirl!
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </LayoutPrimary>
  );
};

export default GalleryPage;
